<template>
  <section id="contentsPlatformSelector">
    <b-row>
      <b-col
        class="ml-1 mt-1"
        style="text-align: start;"
      >
        <h1>{{ $t("contents.contents") }}</h1>
        <h5 class="text-primary">
          {{ $t("contents.selectContent") }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="align-items-center mb-3 mt-3">
      <b-col cols="4">
        <b-form-input
          v-model="search"
          :placeholder="$t('searchContent')"
        />
      </b-col>
      <b-col md="2">
        <b-form-select
          v-if="resCategories"
          id="category"
          v-model="filterCategory"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="text"
        >
          <b-form-select-option :value="null">
            {{ $t('filterByCat') }}
          </b-form-select-option>
          <b-form-select-option
            v-for="c in resCategories"
            :key="c.node.id"
            :value="c.node.id"
          >
            <div class="d-flex ">
              <div class="ml-25 ">
                <b-card-text class="mb-0 font-weight-bold">
                  {{ c.node.name }}
                </b-card-text>
              </div>
            </div>
          </b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col
        cols="3"
        class="d-flex justify-content-center"
      >
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
          class="pt-1"
          @input="fetchData"
        />
      </b-col>
      <b-col
        cols="3"
        class="d-flex justify-content-end"
      >
        <b-button
          :disabled="disabled"
          variant="success"
          class="mr-1"
          @click="seleccionar"
        >
          {{
            $t("dataGeneric.save")
          }}
        </b-button>
      </b-col>
    </b-row>
    <b-col
      v-if="folders.length == 0 && showList != true"
      cols="6"
      class="text-center pr-5"
    >
      <h5 cols="6">
        {{ $t('subscriptions.notFound') }}
      </h5>
    </b-col>

    <b-form-checkbox-group
      id="checkbox-group"
      v-model="selectContent"
      name="selectContent"
      style="height:504px"
      class="scroll-list list-group list-group-flush "
    >
      <b-overlay
        variant="transparent"
        :show="showList"
        :opacity="1.00"
        :blur="'2px'"
        rounded="lg"
      >
        <b-row
          style="text-align: end;"
          class="mr-1"
        >
          <b-col>
            <span v-if="folders && folders.length != 0">{{ $t('resultFounds',{length:rows}) }}</span>
            <span v-else>{{ $t('resultNotFound') }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-for="folder in folders"
            v-show="folders.length != 0"
            :id="folder.node.id"
            :key="folder.node.id"
            class="mt-2"
            md="6"
            lg="3"
            xl="3"
            sm="5"
          >
            <b-card
              :id="folder.node.id"
              class="cardContent cursor-pointer mr-2"
              no-body
              @click.stop.prevent="clickCheck(folder.node)"
            >
              <b-img-lazy
                :src="buildImageUrl(folder)"
                fluid-grow
                class="card-img-top "
                img-alt="Card image"
              />
              <b-card-header>
                <div id="acortarPadre">
                  <b-card-title
                    id="acortar"
                    style="text-align: initial"
                  >
                    <span
                      v-if="folder.node.state == 'GRE'"
                      class="mr-50 bullet bullet-success bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'YEW'"
                      class="mr-50 bullet bullet-warning bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'RED'"
                      class="mr-50 bullet bullet-danger bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'BCK'"
                      class="mr-50 bullet bullet-dark bullet-sm"
                    />
                    {{ folder.node.name }}
                  </b-card-title>

                  <b-card-text
                    class="mb-0 mt-25 text-warning"
                    style="text-align: initial"
                  >
                    {{ folder.node.client?$t('Plataforma')+': '+folder.node.client.name:'' }}
                  </b-card-text>
                </div>
              </b-card-header>
              <b-card-body>
                <div class="actionsBar ">
                  <div class="action ml-1">
                    <b-form-checkbox
                      disabled
                      :value="folder.node.id"
                    />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </b-form-checkbox-group>
  </section>
</template>

<script>

import 'videojs-markers'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import { utf8ToB64, messageError } from '@/store/functions'

import {
  BFormSelect,
  BFormSelectOption,
  BPagination,
  BCard,
  BCol,
  BRow,
  BButton,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BImgLazy,
  BCardText,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,

} from 'bootstrap-vue'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BPagination,
    BImgLazy,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCard,
    BCol,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BButton,
    BCardText,
    BOverlay,
  },
  props: {
    platform: {
      default: '',
    },
    sponsor: {
      type: Boolean,
      default: false,
    },
    code: {
      default: '',
    },
    mainCategory: {
      default: '',
    },
    isPremium: { type: Boolean, default: false }, // Contenido de pago
    typeContent: { default: null },
    podcast: { type: Boolean, default: false },
    exclude: { default: null },
  },
  data() {
    return {
      videojs: null,
      showList: false,
      disabled: true,
      video: false,
      fileUrl: null,
      name: null,
      selected: null,
      cardmoveId: [],
      cardmoveOrder: [],
      orderOrig: null,
      idOrig: null,
      selectContent: [],
      headers: {},
      userData: getUserData(),
      folders: [],
      currentParentFolder: null,
      perPage: 15,
      currentPage: 1,
      rows: null,
      search: '',
      videotype: null,
      filterCategory: null,
      directUrl: null,
      image: null,
      resCategories: [],
      infoContent: [],
      stateColor: {
        GRE: this.$t('ecommerce.public'),
        YEW: this.$t('dataGeneric.internal'),
        RED: this.$t('ecommerce.NoPublic'),
        BCK: this.$t('ecommerce.NoPublic'),
      },
    }
  },
  watch: {
    filterCategory() {
      this.currentPage = 1
      this.fetchData()
    },
    search(oldValue, newValue) {
      if (newValue !== oldValue) {
        if (this.search.length >= 1) {
          this.currentPage = 1
          this.fetchData()
        }
        if (this.search.length === 0) {
          this.folders = []
          this.fetchData()
        }
      }
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    // eslint-disable-next-line no-empty
    } catch (error) {}

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.getCategories()
    this.fetchData()
  },
  beforeDestroy() {
    if (this.videojs) this.videojs.dispose()
  },

  methods: {
    getCategories() {
      const { headers } = this
      const query = `
          {
            allCategories(client:"${this.platform}",isFinal:true) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }`
      axios
        .post('', { query }, { headers }).then(result => {
          this.resCategories = result.data.data.allCategories.edges
        }).catch(() => {

        })
    },
    seleccionar() {
      this.$emit('data', this.selectContent)
    },
    clickRow(cRes, valueSelected) {
      this.selected = valueSelected.id
      this.fileUrl = null
      this.name = null
      this.image = null

      try {
        this.name = cRes[0].node.content
      // eslint-disable-next-line no-empty
      } catch (error) { }

      this.videotype = null
      this.directUrl = null
      this.video = false
      this.image = valueSelected.imageUrl

      try {
        this.videotype = cRes[0].node.resource.videotype
        this.directUrl = cRes[0].node.resource.directUrl
        try {
          this.video = true
          this.fileUrl = cRes[0].node.resource.file.fileUrl
        } catch (error) {
          this.fileUrl = null
          this.video = false
        }
      // eslint-disable-next-line no-empty
      } catch (error) { }

      try {
        if (this.videojs) {
          // eslint-disable-next-line no-underscore-dangle
          if (this.videojs.error_ != null) this.videoMaker()

          if (this.directUrl != null && this.directUrl !== '' && this.directUrl.length !== 0) {
            if (this.directUrl.includes('m3u8')) {
              this.videojs.src({
                type: 'application/x-mpegURL',
                src: this.directUrl,
              })
              this.videoMaker()
            } else {
              this.videojs.src({
                type: 'video/mp4',
                src: this.directUrl,
              })
              this.videoMaker()
            }
          } else if (this.fileUrl != null && this.fileUrl !== '' && this.fileUrl.length !== 0) {
            if (this.fileUrl.includes('m3u8')) {
              this.videojs.src({
                type: 'application/x-mpegURL',
                src: this.fileUrl,
              })
              this.videoMaker()
            } else {
              this.videojs.src({
                type: 'video/mp4',
                src: this.fileUrl,
              })
              this.videoMaker()
            }
          } else {
            this.videojs.src({
              type: '',
              src: '',
            })
            this.videoMaker()
          }
        }
      } catch (error) { console.log(error) }
    },

    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },
    routeToEditView(folderId) {
      this.$router.push({ name: 'contents-edit', params: { id: folderId } })
    },
    fetchData() {
      const { headers } = this

      this.showList = true
      const parent = this.filterCategory != null
        ? utf8ToB64(`id:${this.filterCategory}`)
        : null
      const encondedCode = this.mainCategory !== ''
        ? utf8ToB64(`id:${this.mainCategory}`)
        : null

      let query = ''
      if (this.filterCategory) {
        query = `{
              allCategories(id:"${this.filterCategory}") {
                edges {
                  node {
                    id
                    name
                    categoryContent(${this.search != null ? `name: "${this.search}",` : ''}
                     first:${this.perPage},
                     offset:${this.perPage * (this.currentPage - 1)},
                     orderBy:"order,-created_at") {
                      totalCount
                      edges {
                        node {
                          id
                          name
                          type
                          isPremium
                          hashtag
                          state
                          description
                          modifiedAt
                          publishDate
                          expirationDate 
                          emisionDate
                          isAds
                          isActive
                          order
                          mediaLocation
                          image
                          imageUrl
                          client {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }`
      } else {
        query = `
         query {
                allContents(
                  orderBy:"name"
                  first:${this.perPage},
                  offset:${this.perPage * (this.currentPage - 1)},
                  client:"${this.platform}",
                  ${this.search != null ? `name: "${this.search}",` : ''}
                  ${encondedCode != null
    ? `mainCategory: "${encondedCode}",`
    : ''
}
            ${parent != null
    ? `categories: "${parent}",`
    : ''
}
                  ${this.podcast
    ? `podcast: ${this.podcast},`
    : ''
}
                  ${this.typeContent != null
    ? `type: "${this.typeContent}",`
    : ''
}
                    ${this.exclude != null ? `exclude: ${this.exclude},` : ''}
                  
                  ){
                pageInfo{
                  hasPreviousPage
                  hasNextPage
                }
                totalCount
                edgeCount
                edges{                  
                    node{
                      id
                      client {
                        id
                        name
                      }
                      name
                      type
                      isPremium
                      hashtag
                      state
                      description
                      modifiedAt
                      publishDate
                      expirationDate 
                      emisionDate
                      isAds
                      isActive
                      order
                      mediaLocation
                      image
                      imageUrl
                      contentContentOrder
                      
              ${this.parent
    ? `(category:"${utf8ToB64(
      `category:${this.parent}`,
    )}")`
    : ''
}{
                  totalCount
                  edges {
                    node {
                      id
                      order
                      category {
                        id
                        name
                      }
                    }
                  }
                }
              categories ${this.parent ? `(id : "${this.parent}")` : ''
}{
                totalCount
                edges {
                  node {
                    id
                    name
                    isFinal
                    image
                    imageUrl
                    mediaLocation

                  }
                }
              }
                     
                   }
                  }
                }
              }
        `
      }

      axios
        .post('', {
          query,
        }, { headers })
        .then(response => {
          messageError(response, this)
          let contents = []
          if (this.filterCategory) {
            contents = response.data.data.allCategories.edges[0].node.categoryContent.edges
            this.rows = response.data.data.allCategories.edges[0].node.categoryContent.totalCount
          } else {
            contents = response.data.data.allContents.edges
            this.rows = response.data.data.allContents.totalCount
          }
          this.folders = contents
          this.disabled = this.folders.length === 0

          this.showList = false
        }).catch(error => {
          console.log(error)
        })
    },
    clickCheck(node) {
      const i = this.selectContent.indexOf(node.id)
      const a = this.infoContent.map(e => e.id).indexOf(node.id)

      if (i !== -1) {
        this.selectContent.splice(i, 1)
        this.infoContent.splice(a, 1)
      } else {
        this.selectContent.push(node.id)
        this.infoContent.push(node)
      }
    },
    videoMaker() {
      this.videojs = videojs('demoSelector')

      this.videojs.on('ready', function () {
        try {
          this.videojs.vhs = null
        // eslint-disable-next-line no-empty
        } catch (error) { }
      })
    },
  },
}
</script>

<style >
#contentsPlatformSelector .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 333px;
}

#avatar {
  width: 10rem;
  height: 5.5rem;
}

#contentsPlatformSelector .td {
  width: 50px;
  height: 100px;
}

#contentsPlatformSelector .folder {
  min-height: 150px;
  height: 300px;
  cursor: pointer;
}

#contentsPlatformSelector .dropContainer {
  border: 3px dashed;
}

#contentsPlatformSelector .card-width {
  width: 200px;
}

#contentsPlatformSelector .list-group-item {
  transition: all 1s;
}

#contentsPlatformSelector .cardContent img {
  height: 11vw;
  object-fit: cover;

}

#contentsPlatformSelector .cardContent {
  border: 2px solid #161d31;

}

.check {
  background-color: #212736 !important;
}

/* --------------------- */
.card-img-top {
  /*width: 100%;
  height: 25vh;
  object-fit: cover;*/
}

.actionsBar {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.carpeta img {
  height: 11vw;
  object-fit: cover;
}

.card-body {
  padding: 1rem;
}

.card-body h4 {
  font-size: 1rem !important;
}

.card-header {
  padding: 1rem;
}

.card-header h4 {
  font-size: 1rem !important;
}

.card-body .actionsBar {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;

}

.right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.dropContainer {
  border: 3px dashed;
}

.list-group-item {
  transition: all 1s;
}

.active {
  border: white 1px;
}

.active {
  border: 0px;
}

#acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}
</style>
